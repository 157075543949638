import * as React from 'react';
import { css } from '@emotion/core';
import { Color } from '../CSSConstants';

interface Props {
  children?: React.ReactNode;
  download?: string;
  href?: string;
  onClick?: () => void;
}

const Button = (props: Props): JSX.Element => {
  if (props.href == null) {
    return <button {...props} css={styles.root} />;
  }
  return <a {...props} css={styles.root} />;
};

// Styles

const styles = {
  root: css`
    background: ${Color.midGray};
    border: none;
    color: ${Color.white};
    cursor: pointer;
    display: inline-block;
    font-family: inherit;
    font-size: 0.8em;
    padding: 0.5em 0.7em;
    text-decoration: none;
    text-transform: uppercase;
    transition: background 0.3s;

    & + & {
      margin-left: 8px;
    }

    @media print {
      display: none;
    }

    &:hover {
      background: ${Color.darkGray};
    }

    &::before {
      margin-right: 0.5em;
      vertical-align: -3px;
    }

    .icon {
      height: 16px;
      margin-right: 8px;
      vertical-align: middle;
      width: 16px;
    }
  `,
};

export default Button;
