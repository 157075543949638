import * as React from 'react';
import { css } from '@emotion/core';
import { TSong, TItem } from '../types/Song';
import SongItemContent from './SongItemContent';
import { Breakpoint, Color, Margin } from '../CSSConstants';
// @ts-ignore
import chevronDown from '../images/icon/chevron-down.svg';

const { useEffect, useRef, useState } = React;

interface Props {
  collapsible?: boolean;
  item: TItem;
  song: TSong;
}

const SongItem = ({ collapsible, item, song }: Props): JSX.Element => {
  const articleRef = useRef();
  const [collapsed, setCollapsed] = useState(false);
  const collapsedClass = collapsed ? 'collapsed' : null;

  useEffect(() => {
    if (collapsible) {
      const t = setTimeout(() => setCollapsed(true), 100);
      return () => clearTimeout(t);
    }
  }, [collapsible]);

  return (
    <article className={item.type} css={styles.root} ref={articleRef}>
      <HeaderWrapper onClick={collapsible ? () => setCollapsed(c => !c) : null}>
        <header css={styles.header}>
          <h3 css={styles.title}>
            {getTypeName(item.type)}
            {item.author.name && (
              <>
                {' '}
                <span css={styles.author}>by {item.author.name}</span>
              </>
            )}
          </h3>
        </header>
        {collapsible && (
          <img
            className={collapsedClass}
            css={styles.chevron}
            src={chevronDown}
          />
        )}
      </HeaderWrapper>
      <main className={collapsedClass} css={styles.content}>
        <SongItemContent articleRef={articleRef} item={item} song={song} />
      </main>
    </article>
  );
};

// Helpers

interface HeaderWrapperProps {
  children: React.ReactNode;
  onClick?: (() => void) | void;
}

const HeaderWrapper = ({
  children,
  onClick,
}: HeaderWrapperProps): JSX.Element =>
  onClick ? (
    <button css={styles.headerButton} onClick={onClick}>
      {children}
    </button>
  ) : (
    <>{children}</>
  );

const getTypeName = (
  type: 'chords' | 'lyrics' | 'sheets' | 'youtube',
): string => {
  switch (type) {
    case 'chords':
      return 'Chords';
    case 'lyrics':
      return 'Lyrics';
    case 'sheets':
      return 'Sheet Music';
    case 'youtube':
      return 'Video';
  }
};

// Styles

const styles = {
  root: css`
    background: white;
    padding: 0 ${Margin.default}px;

    @media screen and (max-width: ${Breakpoint.small}px) {
      padding: 0 ${Margin.small}px;
    }

    @media print {
      background: none;
      display: none;
      margin-top: 0;
      padding: 0;

      &.chords.print-target,
      &.lyrics.print-target {
        display: block;

        + .chords,
        + .lyrics {
          margin-top: 2em;
          page-break-before: always;
        }
      }
    }

    & + & {
      margin-top: 2em;

      @media screen and (max-width: ${Breakpoint.small}px) {
        margin-top: ${Margin.small}px;
      }

      @media print {
        margin-top: 0;
      }
    }
  `,
  header: css`
    color: ${Color.darkGray};
    padding: 1em 0;
    position: relative;
  `,
  headerButton: css`
    background: none;
    border: none;
    box-sizing: content-box;
    cursor: pointer;
    font: inherit;
    margin: 0 -${Margin.default}px;
    padding: 0 ${Margin.default}px;
    position: relative;
    text-align: inherit;
    width: 100%;
  `,
  title: css`
    margin: 0;

    @media print {
      font-size: 1em;
      margin-top: 0;
    }
  `,
  author: css`
    color: ${Color.midGray};
  `,
  chevron: css`
    opacity: 0.5;
    position: absolute;
    right: 22px;
    top: calc(50% - 16px);
    transition: transform 200ms;
    width: 32px;

    &.collapsed {
      transform: rotate(-180deg);
    }

    button:hover & {
      opacity: 1;
    }
  `,
  content: css`
    border-top: 2px solid ${Color.midGray};
    overflow: hidden;
    padding: 1em 0;
    transition: border 200ms, padding 200ms;

    &.collapsed {
      border-top: 0;
      height: 0;
      padding-top: 0;
      padding-bottom: 0;
    }
  `,
};

export default SongItem;
