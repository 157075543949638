import * as React from 'react';
import { css } from '@emotion/core';

interface Props {
  videoId: string;
}

const YouTubeVideo = ({ videoId }: Props): JSX.Element => (
  <div css={styles.root}>
    <iframe
      css={styles.iframe}
      width="560"
      height="315"
      src={`https://www.youtube.com/embed/${videoId}`}
      frameBorder="0"
      allowFullScreen
    ></iframe>
  </div>
);

const styles = {
  root: css`
    height: 0;
    overflow: hidden;
    padding-bottom: 56.25%;
    padding-top: 30px;
    position: relative;
  `,
  iframe: css`
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;
  `,
};

export default YouTubeVideo;
