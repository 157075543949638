import * as React from 'react';
import Button from './Button';
// @ts-ignore
import downloadIcon from '../images/icon/download.svg';

type Props =
  | {
      content: string;
      filename: string;
    }
  | {
      filename: string;
      url: string;
    };

const DownloadButton = ({ filename, ...props }: Props): JSX.Element => {
  let href;

  if (hasUrl(props)) {
    href = props.url;
  } else {
    href = `data:text/plain;charset=UTF-8,${encodeURIComponent(
      props.content.replace(/\n/g, '\r\n'),
    )}`;
  }

  return (
    <Button download={filename} href={href}>
      <img className="icon" src={downloadIcon} />
      Download
    </Button>
  );
};

// Helpers
function hasUrl(a: { content?: string; url?: string }): a is { url: string } {
  return !!a.url;
}

export default DownloadButton;
