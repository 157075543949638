import * as React from 'react';
import { css } from '@emotion/core';
import ButtonRow from './ButtonRow';
import DownloadButton from './DownloadButton';
import PrintButton from './PrintButton';

interface Props {
  articleRef: {
    readonly current: HTMLElement | null;
  };
  chords: string;
  name: string;
}

const Chords = ({ articleRef, chords, name }: Props): JSX.Element => (
  <>
    <ButtonRow>
      <PrintButton articleRef={articleRef} />
      <DownloadButton filename={`${name} Chords.txt`} content={chords} />
    </ButtonRow>
    <pre css={styles.chords}>
      {chords.split('\n').map((line, i) => (
        <div css={isLineChords(line) ? styles.chordLine : null} key={i}>
          {line}
        </div>
      ))}
    </pre>
  </>
);

// Helpers
const isLineChords = (line: string): boolean => {
  if (!line) return false;
  if (line.match(/^\s+[A-G][b#]?(m|maj)?[679]?\s/)) return true;
  if (line.match(/^\|:/)) return true;
  if (line.match(/^\| ?[A-G][b#]?(m|maj)[679]? /)) return true;

  const chordRegex = new RegExp(
    '([A-G][b#]?[m]?((6\\/9|11|13|[679]))?' +
      '((dim|dom|aug|sus|min|maj|add|no|m|M|-|\\+)(11|13|15|[23456789])?){0,2}' +
      '([b#\\-\\+][59]){0,2}(\\/[A-G][b#]?)?)(?=(-|/|\\\\|\\)|$))',
  );
  if (line.match(chordRegex)) return true;

  const lineRegex = /^^([A-G][b#]?m?7?\s*)*$/;
  if (line.match(lineRegex)) return true;

  return false;
};

// Styles

const styles = {
  chords: css`
    font-family: 'Source Code Pro', monospace;
    white-space: pre-wrap;
  `,
  chordLine: css`
    color: red;
  `,
};

export default Chords;
