import * as React from 'react';
import { TSong, TItem } from '../types/Song';
import Button from './Button';
import ButtonRow from './ButtonRow';
import YouTubeVideo from './YouTubeVideo';
import Chords from './Chords';
import SheetMusic from './SheetMusic';
import Lyrics from './Lyrics';

interface Props {
  articleRef: {
    readonly current: HTMLElement | null;
  };
  item: TItem;
  song: TSong;
}

const SongItemContent = ({ articleRef, item, song }: Props): JSX.Element => {
  switch (item.type) {
    case 'chords':
      return (
        <Chords articleRef={articleRef} name={song.name} chords={item.data} />
      );
    case 'lyrics':
      return (
        <Lyrics articleRef={articleRef} name={song.name} lyrics={item.data} />
      );
    case 'sheets':
      return (
        <SheetMusic name={song.name} path={`/media/sheets/${item.data}`} />
      );
    case 'youtube':
      return <YouTubeVideo videoId={item.data} />;
  }
};

export default SongItemContent;
