import * as React from 'react';
import Link from 'gatsby-link';
import Helmet from 'react-helmet';
import Layout from '../layout/Layout';
import { TSong, TItem } from '../types/Song';
import SongItem from '../components/SongItem';

interface Props {
  pageContext: {
    song: TSong;
  };
}

const Song = ({ pageContext: { song } }: Props): JSX.Element => (
  <Layout title={song.name}>
    <h2>
      {song.name}
      {song.album != null && ` (${song.album})`}
    </h2>
    {song.items.map((item, i) => (
      <SongItem
        collapsible={song.items.length > 1}
        item={item}
        key={i}
        song={song}
      />
    ))}
  </Layout>
);

export default Song;
