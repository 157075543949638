import * as React from 'react';
import { css } from '@emotion/core';
import { Color } from '../CSSConstants';

interface Props {
  children?: React.ReactNode;
}

const ButtonRow = ({ children }: Props): JSX.Element => (
  <div css={styles.root}>{children}</div>
);

// Styles

const styles = {
  root: css`
    margin-bottom: 1em;
  `,
};

export default ButtonRow;
