import * as React from 'react';
import { css } from '@emotion/core';
import ButtonRow from './ButtonRow';
import DownloadButton from './DownloadButton';
import PrintButton from './PrintButton';

interface Props {
  articleRef: {
    readonly current: HTMLElement | null;
  };
  lyrics: string;
  name: string;
}

const Lyrics = ({ articleRef, lyrics, name }: Props): JSX.Element => (
  <>
    <ButtonRow>
      <PrintButton articleRef={articleRef} />
      <DownloadButton filename={`${name} Lyrics.txt`} content={lyrics} />
    </ButtonRow>
    <pre css={styles.lyrics}>{lyrics}</pre>
  </>
);

// Styles

const styles = {
  lyrics: css`
    font-family: 'Source Code Pro', monospace;
    white-space: pre-wrap;
  `,
};

export default Lyrics;
