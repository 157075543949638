import * as React from 'react';
import { css } from '@emotion/core';
import ButtonRow from './ButtonRow';
import DownloadButton from './DownloadButton';
import PrintButton from './PrintButton';
import { Breakpoint } from '../CSSConstants';

interface Props {
  name: string;
  path: string;
}

const SheetMusic = ({ path, name }: Props): JSX.Element => (
  <>
    <ButtonRow>
      <PrintButton url={path} />
      <DownloadButton filename={`${name} Sheet Music.pdf`} url={path} />
    </ButtonRow>
    <div>
      {path.match(/png|jpg$/i) ? (
        <img css={styles.image} src={path} />
      ) : (
        <iframe
          css={styles.iframe}
          src={`https://docs.google.com/gview?url=http://timminch.in${path}&embedded=true`}
          frameBorder="0"
        ></iframe>
      )}
    </div>
  </>
);

const styles = {
  iframe: css`
    background: #d1d1d1;
    height: 700px;
    width: 100%;

    @media screen and (max-width: ${Breakpoint.medium}) {
      height: 550px;
    }

    @media screen and (max-width: ${Breakpoint.small}) {
      height: 450px;
    }
  `,
  image: css`
    max-height: 100vh;
    max-width: 100%;
  `,
};

export default SheetMusic;
