import * as React from 'react';
import Button from './Button';
// @ts-ignore
import printIcon from '../images/icon/print.svg';

type Props =
  | {
      articleRef: {
        readonly current: HTMLElement | null;
      };
    }
  | {
      url: string;
    };

const PrintButton = (props: Props): JSX.Element => {
  const onClick = () => {
    if (hasUrl(props)) {
      const printWindowFeatures = `menubar=0,toolbar=0,height=${window.innerHeight},width=${window.innerWidth}`;
      const w = window.open(props.url, 'print', printWindowFeatures);
      w.onload = () => w.print();
    } else {
      Array.from(document.querySelectorAll('.print-target')).forEach(el =>
        el.classList.remove('print-target'),
      );
      if (props.articleRef.current) {
        props.articleRef.current.classList.add('print-target');
      }
      window.print();
    }
  };

  return (
    <Button onClick={onClick}>
      <img className="icon" src={printIcon} />
      Print
    </Button>
  );
};

// Helpers
function hasUrl(a: {
  articleRef?: {
    readonly current: HTMLElement | null;
  };
  url?: string;
}): a is { url: string } {
  return !!a.url;
}

export default PrintButton;
